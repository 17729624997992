export const GET_CMS_TEMPLATE_LIST_REQUEST =
  "template/CMS_TEMPLATE_LIST_REQUEST";
export const GET_CMS_TEMPLATE_LIST_SUCCESSFUL =
  "template/CMS_TEMPLATE_LIST_SUCCESSFUL";
export const GET_CMS_TEMPLATE_LIST_FAIL = "template/CMS_TEMPLATE_LIST_FAIL";

export const GET_CMS_TEMPLATE_LIST_STATE_REQUEST =
  "template/GET_CMS_TEMPLATE_LIST_STATE_REQUEST";
export const GET_CMS_TEMPLATE_LIST_STATE_SUCCESSFUL =
  "template/GET_CMS_TEMPLATE_LIST_STATE_SUCCESSFUL";
export const GET_CMS_TEMPLATE_LIST_STATE_FAIL =
  "template/GET_CMS_TEMPLATE_LIST_STATE_FAIL";

export const DELETE_CMS_TEMPLATE_REQUEST =
  "template/DELETE_CMS_TEMPLATE_REQUEST";
export const DELETE_CMS_TEMPLATE_SUCCESSFUL =
  "template/DELETE_CMS_TEMPLATE_SUCCESSFUL";
export const DELETE_CMS_TEMPLATE_FAIL = "template/DELETE_CMS_TEMPLATE_FAIL";
